import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Nav, Navbar } from "react-bootstrap";
import { APP_CONST } from "../config/const";
import ModalWrapper from "./ModalWrapper";
import logo from "../assets/images/logo.webp";
import Menu from "../assets/images/icons/toggle-icon.png";

import Sports from "../assets/images/games-icon/headicon/sports.png";
import Exchange from "../assets/images/games-icon/headicon/exchange.png";
import Sportsbook from "../assets/images/games-icon/headicon/sportsbook.png";
import Evolution from "../assets/images/games-icon/headicon/evolution.png";
import Ezugi from "../assets/images/games-icon/headicon/ezugi.png";
import Slots from "../assets/images/games-icon/headicon/slots.png";
import Kingmaker from "../assets/images/games-icon/headicon/kingmaker.png";
import Aviator from "../assets/images/games-icon/Aviator.png";

const Header = () => {
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);
  const [showAuthModals, setShowAuthModals] = useState(false);
  const [defaultModal, setDefaultModal] = useState("login");
  const handleModalShow = (modalName) => {
    setShowAuthModals(true);
    setDefaultModal(modalName);
  };

  return (
    <header className="header beforeheader">
      <div className="d-flex align-items-center h-100">
        <div className="logo">
          <a href="/">
            <img src={appDetails?.LOGO_URL} alt="Logo" />
            {/* <img src={logo} alt="Logo" /> */}
          </a>
        </div>

        <div className="headerRight ms-auto">
          <div className="bonus-head me-2">
            <a href="/bonus">
              <span>Bonus</span>
              <div className="notification"></div>
            </a>
          </div>

          <Button
            variant="primary"
            onClick={() => {
              handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
            }}
            className="btn_signin"
          >
            Log In
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              handleModalShow(APP_CONST.AUTH_MODAL.REGISTER);
            }}
            className="btn_signup"
          >
            Join Us
          </Button>
        </div>
      </div>

      {/* <div xs={12} className="bottom_head">
        <Navbar expand="md">
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/sign-in">
                <img src={Sports} />
                Sports
              </Nav.Link>
              <Nav.Link href="/sign-in">
                <img src={Exchange} />
                Exchange
              </Nav.Link>
              <Nav.Link href="/sign-in" className="">
                <img src={Sportsbook} />
                Sportsbook
              </Nav.Link>
              <Nav.Link href="/sign-in">
                <img src={Evolution} />
                Evolution
              </Nav.Link>
              <Nav.Link href="/sign-in">
                <img src={Ezugi} />
                Ezugi
              </Nav.Link>
              <Nav.Link href="/sign-in">
                <img src={Slots} />
                Slots
              </Nav.Link>
              <Nav.Link href="/sign-in">
                <img src={Kingmaker} />
                Kingmaker
              </Nav.Link>
              <Nav.Link href="/sign-in">
                <img src={Aviator} />
                Aviaitor
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div> */}
      {showAuthModals && (
        <ModalWrapper
          defaultShow={defaultModal}
          handleClose={setShowAuthModals}
        />
      )}
    </header>
  );
};

export default Header;
