import React, { useState, useEffect } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import {
  checkIfUserNameExist,
  mobileVerification,
  registerUser,
  sendOtpToMobile,
} from "../../../redux/auth/actions";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { APP_CONST } from "../../../config/const";
import { Toaster } from "../../../utils/toaster";
import { parsePhoneNumber } from "libphonenumber-js";
import PhoneInput from "react-phone-input-2";
import { FiEye, FiEyeOff } from "react-icons/fi";
// import LoginLeftPart from "../../../assets/images/modal.gif";

import LgnSgnpBannerDesk from "../../../assets/images/icons/home-page/login-desktop.webp";
import LgnSgnpBannerMob from "../../../assets/images/icons/home-page/login-mobile.webp";
import NextIcon from "../../../assets/images/icons/arrow-next.png";

const RegisterModal = ({
  show,
  handleClose,
  handleModalShow,
  agentCode: agentCodeProp,
}) => {
  const { appDetails } = useSelector((state) => state.app);
  const validationMessages = [
    { text: "at least 8 symbols", key: 1 },
    { text: "at least 1 digit (0-9)", key: 2 },
    { text: "at least 1 lowercase characters (a-z)", key: 3 },
    { text: "at least 1 uppercase characters (A-Z)", key: 4 },
  ];
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [showPasswordValidtions, setShowPasswordValidations] = useState(false);
  const [validationIndex, setValidationIndex] = useState([]);

  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});

  const [referalCode, setReferalCode] = useState(location?.state?.referalCode);
  const [isReffered, setIsReffered] = useState(false);
  const [cookies, setCookie] = useCookies(["refferal"]);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [agentCode, setAgentCode] = useState(agentCodeProp);
  const [agentCookies, setAgentCookie] = useCookies(["agentCode"]);
  const [mobileNumber, setMobileNumber] = useState("");
  const [otp, setOtpValue] = useState("");
  const [userNameConfig, setUserNameConfig] = useState({
    isUserNameExist: false,
    message: "User Name is available",
  });

  const checkForPasswordValidations = (e) => {
    const password = e.target.value;
    let fulfilledindex = [];
    if (password.length >= 8) {
      fulfilledindex.push(1);
    }
    if (/\d/.test(password)) {
      fulfilledindex.push(2);
    }
    const smallAlp = new RegExp("(?=.*[a-z])");
    if (smallAlp.test(password)) {
      fulfilledindex.push(3);
    }
    const capAlp = new RegExp("(?=.*[A-Z])");
    if (capAlp.test(password)) {
      fulfilledindex.push(4);
    }
    setValidationIndex(fulfilledindex);
  };

  useEffect(() => {
    if (referalCode) {
      setCookie("code", referalCode);
      setReferalCode(referalCode);
      setIsReffered(true);
    } else if (cookies?.code) {
      setReferalCode(cookies.code);
      setIsReffered(true);
    }
  }, [cookies, referalCode]);

  useEffect(() => {
    if (agentCode) {
      setAgentCookie("agent", agentCode);
    } else if (agentCookies?.agent) {
      setAgentCode(agentCookies?.agent);
    }
  }, [agentCookies, agentCode]);

  // useEffect(() => {
  //   if (show) {
  //     const backdrops = document.querySelectorAll(".modal-backdrop");
  //     if (backdrops.length > 0) {
  //       backdrops[backdrops.length - 1].style.zIndex = "9999999996";
  //     }
  //   }
  // }, [show]);

  const validateData = (data = null) => {
    const dataToValidate = data ? data : formData;
    const { name, username, password, confirmPassword } = dataToValidate;
    const newErrors = {};
    if (!name || name === "") {
      newErrors.name = "Name cannot be empty";
    }
    if (!username || username === "") {
      newErrors.userName = "User name cannot be empty";
    }
    if (username && (username.length < 4 || username.length > 15)) {
      newErrors.userName = "Username must be 4 to 15 characters.";
    }
    if (!/^[A-Za-z0-9 ]+$/.test(username)) {
      newErrors.userName = "Special characters are not allowed in username.";
    }
    if (!password || password === "") {
      newErrors.password = "Password cannot be empty";
    }
    if (password && (password.length < 8 || password.length > 20)) {
      newErrors.password = "Password must be 8 to 20 characters.";
    }
    if (!confirmPassword || confirmPassword === "") {
      newErrors.confirmPassword = "Confirm password cannot be empty";
    } else if (password !== confirmPassword) {
      newErrors.confirmPassword = "Password and Confirm password must be same";
    }
    setErrors(newErrors);
    return newErrors;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let errorsData = validateData();

    if (Object.keys(errorsData).length > 0) {
      //setErrors(errorsData);
    } else {
      let dataToSend = { ...formData };
      delete dataToSend.confirmPassword;
      if (agentCode) {
        dataToSend.agent_code = agentCode;
      }
      dispatch(
        registerUser(
          {
            ...dataToSend,
            typeId: 3,
            parentId: 81026,
            mobile: registerConfig.mobileDetails?.mobile,
            country_code: registerConfig.mobileDetails.country_code,
          },
          navigate
        )
      );
      // checkIfExistingUserDetails(formData);
    }
  };

  const handleChange = (e) => {
    let fieldName = e.target.name;
    let fieldValue = e.target.value;
    let tempFormData = { ...formData, [fieldName]: fieldValue };
    setFormData(tempFormData);
    validateData(tempFormData);
  };
  const [registerConfig, setRegisterConfig] = useState({
    isOtpEnabled: useSelector((state) => state?.app?.appDetails) === 0,
    mobileDetails: {},
    isSendingOtp: false,
    isVerifyingOtp: false,
    isOtpSent: false,
    isOtpVerified: false,
    showPassword: false,
    showConfirmPassword: false,
  });
  const handleRegisterConfigChange = (config) => {
    setRegisterConfig((p) => ({ ...p, ...config }));
  };
  const sendOtp = () => {
    handleRegisterConfigChange({
      isSendingOtp: true,
    });
    const phoneNumber = parsePhoneNumber("+" + mobileNumber);
    let payload = {};
    payload.mobile = phoneNumber.nationalNumber;
    payload.country_code = phoneNumber.countryCallingCode;

    dispatch(sendOtpToMobile(payload))
      .then((res) => {
        handleRegisterConfigChange({
          mobileDetails: payload,
          isOtpSent: true,
          isSendingOtp: false,
        });
        if (res?.data?.error === false) {
          Toaster(
            APP_CONST.TOAST_TYPE.SUCCESS,
            res?.data?.message || "OTP sent"
          );
        } else {
          Toaster(APP_CONST.TOAST_TYPE.ERROR, res?.data?.message);
        }
      })
      .catch((err) => {
        Toaster(APP_CONST.TOAST_TYPE.ERROR, err?.response?.data?.message);
        handleRegisterConfigChange({
          isSendingOtp: false,
        });
      });
  };
  const verifyOtp = () => {
    handleRegisterConfigChange({
      isVerifying: true,
    });
    dispatch(mobileVerification({ ...registerConfig.mobileDetails, otp }))
      .then((res) => {
        handleRegisterConfigChange({
          isVerifying: false,
          isOtpVerified: true,
        });
        Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "OTP verified");
      })
      .catch((err) => {
        handleRegisterConfigChange({
          isVerifying: false,
        });
        Toaster(APP_CONST.TOAST_TYPE.ERROR, err.response.data.message);
      });
  };

  const checkUserName = async () => {
    if (formData?.username) {
      const response = await checkIfUserNameExist({
        username: formData?.username,
      });
      if (response && response?.data?.error === false) {
        setUserNameConfig({
          userNameExist: false,
          message: "username is available",
        });
      } else {
        setUserNameConfig({
          isUserNameExist: true,
          message: "username is already taken",
        });
      }
    }
  };

  return (
    <Modal
      show={show}
      className={"steps-canvas lgn-reg-modal register"}
      onHide={() => {
        console.log("working");
      }}
      placement={"end"}
    >
      <Modal.Header
        closeButton
        onClick={() => handleClose(APP_CONST.AUTH_MODAL_ACTION_TYPE.MANUAL)}
        closeVariant="white"
      ></Modal.Header>

      <Modal.Body>
        <div className="banner-box">
          <img
            src={LgnSgnpBannerDesk}
            alt="login signup banner"
            className="desk-v"
          />
          <img
            src={LgnSgnpBannerMob}
            alt="login signup banner"
            className="mob-v"
          />
        </div>

        <div className="info-section">
          <Modal.Title>Join Us</Modal.Title>
          <div className="form-steps">
            <Form>
              <Form.Group className="form-group" controlId="formBasicPassword">
                <Form.Control
                  type={"text"}
                  name="name"
                  placeholder="Name*"
                  onChange={handleChange}
                  isInvalid={!!errors.name}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.name}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="form-group" controlId="formBasicPassword">
                <Form.Control
                  type="text"
                  name="username"
                  placeholder="Username*"
                  onChange={handleChange}
                  isInvalid={!!errors.userName}
                  onBlur={checkUserName}
                />

                <Form.Control.Feedback type="invalid">
                  {errors?.userName}
                </Form.Control.Feedback>
              </Form.Group>
              <div
                className="user_avail"
                style={
                  userNameConfig.isUserNameExist
                    ? { color: "#bc2f3e", fontSize: "12px" }
                    : { color: "#198754", fontSize: "12px" }
                }
              >
                {formData?.username && userNameConfig.message}
              </div>
              <Form.Group className="form-group" controlId="formBasicPassword">
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  name="password"
                  placeholder="Password*"
                  autoComplete="new-password"
                  onChange={(e) => {
                    handleChange(e);
                    checkForPasswordValidations(e);
                  }}
                  isInvalid={!!errors.password}
                  onFocus={() => {
                    setShowPasswordValidations(true);
                  }}
                />
                <span
                  className="icon"
                  onClick={() => setShowPassword((p) => !p)}
                >
                  {showPassword ? <FiEyeOff /> : <FiEye />}
                </span>
                <Form.Control.Feedback type="invalid">
                  {errors?.password}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="form-group" controlId="formBasicPassword">
                <Form.Control
                  type={showConfirmPassword ? "text" : "password"}
                  name="confirmPassword"
                  placeholder="confirm password*"
                  onChange={handleChange}
                  isInvalid={!!errors.confirmPassword}
                  onFocus={() => {
                    setShowPasswordValidations(true);
                  }}
                />
                <span
                  className="icon"
                  onClick={() => setShowConfirmPassword((p) => !p)}
                >
                  {showConfirmPassword ? <FiEyeOff /> : <FiEye />}
                </span>
                <Form.Control.Feedback type="invalid">
                  {errors?.confirmPassword}
                </Form.Control.Feedback>
              </Form.Group>
              {showPasswordValidtions && (
                <div className="password-note">
                  <ul>
                    {validationMessages.map((item, index) => {
                      const result = validationIndex.find(
                        (i) => i === item.key
                      );
                      return (
                        <li className={result ? "pwd-validation-message" : ""}>
                          {item?.text}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
              <Form.Group className="form-group" controlId="formBasicPassword">
                <Form.Control
                  type={"text"}
                  name="agent_code"
                  placeholder="Please enter agent code"
                  value={agentCode}
                  onChange={(e) => setAgentCode(e.target.value)}
                />
              </Form.Group>

              <div className="get-otp">
                <Form.Group
                  className="form-group"
                  controlId="formBasicPassword"
                >
                  <PhoneInput
                    country={"in"}
                    countryCodeEditable={false}
                    inputProps={{
                      name: "phone",
                      required: true,
                    }}
                    inputStyle={{ width: "100%" }}
                    onChange={(phone) => {
                      setMobileNumber(phone);
                    }}
                  />
                </Form.Group>

                <Button
                  variant="primary"
                  onClick={sendOtp}
                  disabled={
                    registerConfig.isSendingOtp ||
                    userNameConfig.isUserNameExist === true
                  }
                >
                  {registerConfig.isSendingOtp
                    ? "Loading.."
                    : registerConfig.isOtpSent === true
                    ? "Resend OTP"
                    : "Get OTP"}
                </Button>
                <p className="note">
                  <span>Note:</span> user can request a new otp after every five
                  minutes.
                </p>
              </div>
              <div className="verify-otp">
                <Form.Group
                  className="form-group mb-0"
                  controlId="formBasicPassword"
                >
                  <Form.Control
                    type="text"
                    placeholder="Enter OTP*"
                    onChange={(e) => setOtpValue(e.target.value)}
                  />
                </Form.Group>

                <Button
                  variant="primary"
                  onClick={verifyOtp}
                  disabled={
                    registerConfig.isVerifyingOtp ||
                    !registerConfig.isOtpSent ||
                    !otp
                  }
                >
                  {!registerConfig.isOtpVerified
                    ? !registerConfig.isVerifyingOtp
                      ? "VERIFY OTP"
                      : "SUBMITTING"
                    : "VERIFIED"}
                </Button>
              </div>

              <div className="sbmt-btn">
                <Button
                  variant="primary"
                  type="submit"
                  className="register mb-0"
                  onClick={handleSubmit}
                  disabled={
                    !registerConfig.isOtpVerified ||
                    userNameConfig.isUserNameExist === true
                  }
                >
                  Register
                </Button>
              </div>
            </Form>

            <div className="no-yes-ac">
              <span className="text">
                Already have an account? &nbsp;&nbsp;
              </span>
              <a
                href="#"
                onClick={() => {
                  handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
                  handleClose(APP_CONST.AUTH_MODAL.REGISTER);
                }}
              >
                Login <img src={NextIcon} alt="next" />
              </a>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default RegisterModal;
