import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import {
  demoLogin,
  forgotPassword,
  loginUser,
  resetPassword,
} from "../../../redux/auth/actions";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Button, Dropdown, Form } from "react-bootstrap";
// import LoginLeftPart from "../../../assets/images/modal.gif";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { APP_CONST } from "../../../config/const";
// import MumbaiSports from "../../../assets/images/icons/mumbaisports.png";

// import UserName from "../../../assets/images/icons/icon-user.png";
// import PasswordIcon from "../../../assets/images/icons/icon-pass.png";

import PhoneInput from "react-phone-input-2";
import { Toaster } from "../../../utils/toaster";
import { parsePhoneNumber } from "libphonenumber-js";
import BackArrow from "../../../assets/svg/back-arrow.svg";
import { checkIfDemoUserExist } from "../../../redux/app/actions";

import LgnSgnpBannerDesk from "../../../assets/images/icons/home-page/login-desktop.webp";
import LgnSgnpBannerMob from "../../../assets/images/icons/home-page/login-mobile.webp";
import NextIcon from "../../../assets/images/icons/arrow-next.png";

const LoginModal = ({ show, handleClose, handleModalShow }) => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const { appDetails } = useSelector((state) => state.app);
  const [mobileNumber, setMobileNumber] = useState("");
  const [changeModalContent, setChangeModalContent] = useState(true);
  const [otp, setOtpValue] = useState("");
  const [password, setPassword] = useState("");
  const [isVerifying, setIsVerifying] = useState(false);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isDemoUserExist, setIsDemoUserExist] = useState(false);

  useEffect(() => {
    checkDemoUser();
  }, []);

  const checkDemoUser = async () => {
    const response = await checkIfDemoUserExist();
    if (response && response?.data?.error === false) {
      setIsDemoUserExist(response?.data?.data?.hasDemoUsers === true);
    }
  };
  const validateData = () => {
    const { username, password } = formData;
    const newErrors = {};
    if (!username || username === "") {
      newErrors.username = "Username cannot be empty";
    }
    if (!password || password === "") {
      newErrors.password = "Password cannot be empty";
    }
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errorData = validateData();
    if (Object.keys(errorData).length > 0) {
      setErrors(errorData);
    } else {
      let loginData = { ...formData };
      // handleClose();
      dispatch(loginUser(loginData));
    }
  };

  const handleChange = (e) => {
    let fieldName = e.target.name;
    let fieldValue = e.target.value;
    let tempFormData = { ...formData, [fieldName]: fieldValue };
    setFormData(tempFormData);
  };

  const handleSubmitForgotPass = async (e) => {
    e.preventDefault();
    const phoneNumber = parsePhoneNumber("+" + mobileNumber);
    let payload = {};
    if (phoneNumber) {
      payload.phone = phoneNumber.nationalNumber;
      payload.countryCode = phoneNumber.countryCallingCode;
      forgotPassword(payload)
        .then((res) => {
          if (res.data.error === false) {
            Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "OTP sent");
            setIsOtpSent(true);
            // navigate("/reset-password");
            // handleClose();
            // handleModalShow(APP_CONST.AUTH_MODAL.RESET_PASSWORD);
          }
        })
        .catch((err) => {
          Toaster(APP_CONST.TOAST_TYPE.ERROR, err.response.data.message);
        });
    } else {
      Toaster(APP_CONST.TOAST_TYPE.ERROR, "Please enter mobile number");
    }
  };

  const handleSubmitResetPass = () => {
    setIsVerifying(true);

    if (otp == "" || password == "") {
      Toaster(APP_CONST.TOAST_TYPE.ERROR, "All fields are mandatory");
    } else {
      const payload = {
        otp,
        password,
      };

      resetPassword(payload)
        .then((res) => {
          Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Password reset success");
          // navigate("/sign-in");
          // handleClose();
          // handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
          setChangeModalContent((prev) => !prev);
        })
        .catch((err) => {
          Toaster(
            APP_CONST.TOAST_TYPE.ERROR,
            err.response.data.message || "Request failed"
          );
          setIsVerifying(false);
        });
    }
  };

  const handleDemoLogin = () => {
    dispatch(demoLogin());
  };

  return (
    <Modal
      show={show}
      id="loginModal"
      className={"steps-canvas lgn-reg-modal login"}
      onHide={() => {
        console.log("working");
      }}
      placement={"end"}
    >
      <Modal.Header
        closeButton
        onClick={() => handleClose(APP_CONST.AUTH_MODAL_ACTION_TYPE.MANUAL)}
        closeVariant="white"
      ></Modal.Header>
      <Modal.Body>
        <div className="banner-box">
          <img
            src={LgnSgnpBannerDesk}
            alt="login signup banner"
            className="desk-v"
          />
          <img
            src={LgnSgnpBannerMob}
            alt="login signup banner"
            className="mob-v"
          />
        </div>

        {changeModalContent ? (
          <div
            className={
              !changeModalContent ? `info-section` : `info-section active`
            }
          >
            <Modal.Title>Login</Modal.Title>
            <div className="form-steps">
              <Form>
                <Form.Group className="form-group" controlId="formBasicName">
                  <Form.Control
                    type="text"
                    placeholder="Username *"
                    name="username"
                    onChange={handleChange}
                    isInvalid={!!errors.username}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors?.username}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  className="form-group"
                  controlId="formBasicPassword"
                >
                  <Form.Control
                    type={showPassword ? "text" : "password"}
                    placeholder="Password *"
                    name="password"
                    onChange={handleChange}
                    isInvalid={!!errors.password}
                  />
                  <span
                    className="icon"
                    onClick={() => setShowPassword((s) => !s)}
                  >
                    {showPassword ? <FiEyeOff /> : <FiEye />}
                  </span>
                  <Form.Control.Feedback type="invalid">
                    {errors?.password}
                  </Form.Control.Feedback>
                </Form.Group>

                <div className="frgt-link">
                  <a
                    onClick={() => {
                      // handleClose();
                      // handleModalShow(APP_CONST.AUTH_MODAL.FORGOTO_PASSWORD);
                      setChangeModalContent((prev) => !prev);
                    }}
                  >
                    Forgot password?
                  </a>
                </div>
                <Button variant="primary" type="submit" onClick={handleSubmit}>
                  Login
                </Button>
              </Form>

              {isDemoUserExist && (
                <>
                  <div className="demo-ac">
                    <span className="text">
                      Need Demo? Just Click the button below.
                    </span>
                    <Button variant="primary" onClick={handleDemoLogin}>
                      Demo Login
                    </Button>
                  </div>
                </>
              )}

              <div className="no-yes-ac">
                <span className="text">Not registered? &nbsp;&nbsp;</span>
                <a
                  href="#"
                  onClick={() => {
                    handleModalShow(APP_CONST.AUTH_MODAL.REGISTER);
                    handleClose(APP_CONST.AUTH_MODAL.LOGIN);
                  }}
                >
                  Join Us <img src={NextIcon} alt="next" />
                </a>
              </div>
            </div>
          </div>
        ) : (
          <div
            className={
              changeModalContent
                ? `info-section`
                : `info-section ForgotPasswordSection`
            }
            // {`login-section  ForgotPasswordSection `}
          >
            <div className="back-btn">
              <a
                href="#"
                className="back-btn"
                onClick={() => setChangeModalContent((prev) => !prev)}
              >
                <img src={BackArrow} alt="back" /> <span>Back</span>
              </a>
            </div>
            <Modal.Title>Password recovery</Modal.Title>
            <div className="form-steps">
              <Form onSubmit={handleSubmitForgotPass}>
                <Form.Group
                  className="form-group"
                  controlId="formBasicPassword"
                >
                  <PhoneInput
                    country={"in"}
                    countryCodeEditable={false}
                    inputProps={{
                      name: "phone",
                      required: true,
                    }}
                    // onlyCountries={["in"]}
                    inputStyle={{
                      width: "100%",
                    }}
                    onChange={(phone) => {
                      // alert("phone", phone);
                      // alert(phone);
                      setMobileNumber(phone);
                    }}
                  />
                </Form.Group>
                <Button
                  variant="primary mb-4"
                  type="submit"
                  // onClick={handleSubmitForgotPass}
                >
                  Get OTP
                </Button>

                <Form.Group className="form-group" controlId="">
                  <Form.Control
                    type="text"
                    placeholder="Enter OTP*"
                    onChange={(e) => setOtpValue(e.target.value)}
                    autoComplete="off"
                  />
                </Form.Group>
                <Form.Group className="form-group" controlId="">
                  <Form.Control
                    type={showPassword ? "text" : "password"}
                    placeholder="Enter new password"
                    onChange={(e) => setPassword(e.target.value)}
                    autoComplete="new-password"
                  />
                  <span
                    className="icon"
                    onClick={() => setShowPassword((s) => !s)}
                  >
                    {showPassword ? <FiEyeOff /> : <FiEye />}
                  </span>
                </Form.Group>

                <div className="sbmt-btn">
                  <Button
                    variant="primary"
                    onClick={handleSubmitResetPass}
                    disabled={!isOtpSent}
                  >
                    {!isVerifying ? "SUBMIT" : "SUBMITTING"}
                  </Button>
                </div>
              </Form>

              <div className="no-yes-ac">
                <span className="text">Not registered? &nbsp;&nbsp;</span>
                <a
                  href="#"
                  onClick={() => {
                    handleModalShow(APP_CONST.AUTH_MODAL.REGISTER);
                    handleClose(APP_CONST.AUTH_MODAL.LOGIN);
                  }}
                >
                  Join Us <img src={NextIcon} alt="next" />
                </a>
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};
export default LoginModal;
