import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import React, { useRef } from "react";

const styles = {
  visibility: {
    //for desktop only
    desktop: {
      position: "br", // bottom-right
      xOffset: 15, // 15px away from right
      yOffset: 40, // 40px up from bottom
    },
    // for mobile only
    mobile: {
      position: "bl", // bottom-left
      xOffset: 5, // 5px away from left
      yOffset: 50, // 50px up from bottom
    },
  },
};

const TawkChat = React.forwardRef((props, ref) => (
  <TawkMessengerReact
    propertyId="6738b32f4304e3196ae397da"
    widgetId="default"
    customStyle={styles}
    ref={ref}
  />
));

export default TawkChat;
