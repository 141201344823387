export const pageUrlGenerator = (page) =>
  `${window.location.protocol}//${window.location.host}${page}`;

export const casinoTabs = {
  popular: {
    OneDayTeenPattiPR: {
      href: "/casino/ezg-one-day-teen-patti",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/popular/new/onedayteenpatti.jpg",
      alt: "one day teen patti",
      name: "One Day Teen Patti",
    },
    AviatorPR: {
      href: "/casino/spribe/aviator",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/popular/new/Aviator.jpg",
      alt: "aviator",
      name: "Aviator",
    },
    AndarBaharPR: {
      href: "/casino/ezg-andar-bahar",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/popular/new/AndarBahar.jpg",
      alt: "andar bahar",
      name: "Andar Bahar",
    },
    Cards32PR: {
      href: "/casino/ezg-32-cards",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/popular/new/32Cards.jpg",
      alt: "32 cards",
      name: "32 Cards",
    },
    CrazyTimePR: {
      href: "/casino/ezgevo-crazy-time",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/popular/new/CrazyTime.jpg",
      alt: "crazy time",
      name: "Crazy Time",
    },
    DoubleBallRoulettePR: {
      href: "/casino/evolution/DoubleBallRoulette",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/popular/new/DOubleballROulette.jpg",
      alt: "double ball roulette",
      name: "Double Ball Roulette",
    },
    DragonTiger1PR: {
      href: "/casino/evolution/DragonTiger1",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/popular/new/dragontiger.jpg",
      alt: "dragon tiger 1",
      name: "Dragon Tiger 1",
    },
    ItalianRoulettePR: {
      href: "/casino/ezg-italian-roulette",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/popular/new/ItalianRoulette.jpg",
      alt: "italian roulette",
      name: "Italian Roulette",
    },
    // Lucky7PR: {
    //   href: "/casino/ezugi/sevenLucky",
    //   code: "",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: pageUrlGenerator("/home"),
    //   imgUrl: "./images/popular/new/Lucky7-2.jpg",
    //   alt: "seven lucky",
    //   name: "Seven Lucky",
    // },
  },
  ezugi: {
    SpeedRouletteEZ: {
      href: "/casino/ezg-speed-roulette",
      code: "",
      casino: "ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/ezugi/Speed-Roulette1.png",
      alt: "speed roulette",
      name: "Speed Roulette",
    },
    DiamondRouletteEZ: {
      href: "/casino/ezg-diamond-roulette",
      code: "",
      casino: "ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/ezugi/Diamond-roulette1.png",
      alt: "diamond roulette",
      name: "Diamond Roulette",
    },
    NamasteRouletteEZ: {
      href: "/casino/ezg-namaste-roulette",
      code: "",
      casino: "ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/ezugi/Namaste-roulette1.png",
      alt: "namaste roulette",
      name: "Namaste Roulette",
    },
    AutoRouletteEZ: {
      href: "/casino/ezg-auto-roulette",
      code: "",
      casino: "ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/ezugi/Auto-roulette.png",
      alt: "auto roulette",
      name: "Auto Roulette",
    },
    SpeedAutoRouletteEZ: {
      href: "/casino/ezg-speed-auto-roulette",
      code: "",
      casino: "ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/ezugi/Speed-auto-roulette.png",
      alt: "speed auto roulette",
      name: "Speed Auto Roulette",
    },
    OracleRoulette2EZ: {
      href: "/casino/ezg-oracle-roulette-2",
      code: "",
      casino: "ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/ezugi/Oracle-360-roulette.png",
      alt: "oracle roulette 2",
      name: "Oracle Roulette 2",
    },
    ItalianRouletteEZ: {
      href: "/casino/ezg-italian-roulette",
      code: "",
      casino: "ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/ezugi/Italian-roulette1.png",
      alt: "italian roulette",
      name: "Italian Roulette",
    },
    SpanishRouletteEZ: {
      href: "/casino/ezg-spanish-roulette",
      code: "",
      casino: "ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/ezugi/Spanish-roulette.png",
      alt: "spanish roulette",
      name: "Spanish Roulette",
    },
  },
  evolution: {
    InstantRouletteEvo: {
      href: "/casino/ezgevo-instant-roulette",
      code: "",
      casino: "evolution",
      provider: "Evolution",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/evolution/Instantroulette.png",
      alt: "instant roulette",
      name: "Instant Roulette",
    },
    DragonTigerEvo: {
      href: "/casino/ezgevo-dragon-tiger-evo",
      code: "",
      casino: "evolution",
      provider: "Evolution",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/evolution/DragonTiger.png",
      alt: "dragon tiger evo",
      name: "Dragon Tiger Evo",
    },
    BaccaratAEvo: {
      href: "/casino/ezgevo-baccarat-a",
      code: "",
      casino: "evolution",
      provider: "Evolution",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/evolution/Baccart.png",
      alt: "baccarat a",
      name: "Baccarat A",
    },
    BlackjackAEvo: {
      href: "/casino/ezgevo-blackjack-a",
      code: "",
      casino: "evolution",
      provider: "Evolution",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/evolution/Blackjack.png",
      alt: "blackjack a",
      name: "Blackjack A",
    },
    CrazyTimeEvo: {
      href: "/casino/ezgevo-crazy-time",
      code: "",
      casino: "evolution",
      provider: "Evolution",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/evolution/CrazyTime.png",
      alt: "crazy time",
      name: "Crazy Time",
    },
    MegaballEvo: {
      href: "/casino/evolution/Megaball",
      code: "",
      casino: "evolution",
      provider: "Evolution",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/evolution/Megaball.png",
      alt: "megaball",
      name: "Megaball",
    },
    SuperSicBoEvo: {
      href: "/casino/ezgevo-super-sic-bo",
      code: "",
      casino: "evolution",
      provider: "Evolution",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/evolution/Supersicbo.png",
      alt: "super sic bo",
      name: "Super Sic Bo",
    },
  },
  spribe: {
    mines: {
      href: "/casino/spribe/mines",
      code: "mines",
      casino: "spribe",
      provider: "mines",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/spribe/mines.png",
      alt: "mines",
      name: "Mines",
    },
    goal: {
      href: "/casino/spribe/goal",
      code: "goal",
      casino: "spribe",
      provider: "goal",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/spribe/goal.png",
      alt: "goal",
      name: "Goal",
    },
    dice: {
      href: "/casino/spribe/dice",
      code: "dice",
      casino: "spribe",
      provider: "dice",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/spribe/dice.png",
      alt: "dice",
      name: "Dice",
    },
    aviator: {
      href: "/casino/spribe/aviator",
      code: "aviator",
      casino: "spribe",
      provider: "aviator",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/spribe/aviator.png",
      alt: "aviator",
      name: "Aviator",
    },
    plinko: {
      href: "/casino/spribe/plinko",
      code: "plinko",
      casino: "spribe",
      provider: "plinko",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/spribe/plinko.png",
      alt: "plinko",
      name: "Plinko",
    },
    miniroulette: {
      href: "/casino/spribe/miniroulette",
      code: "miniroulette",
      casino: "spribe",
      provider: "miniroulette",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/spribe/mini-roulette.png",
      alt: "mini roulette",
      name: "Mini Roulette",
    },
    hilo: {
      href: "/casino/spribe/hilo",
      code: "hilo",
      casino: "spribe",
      provider: "hilo",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/spribe/hilo.png",
      alt: "hilo",
      name: "Hilo",
    },
  },
  aura: {
    BaccaratAura: {
      href: "/casino/aura-baccarat",
      code: "",
      casino: "Aura Gaming",
      provider: "Aura",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/aura/Baccarat.png",
      alt: "baccarat",
      name: "Baccarat",
    },
    DreamCatcherAura: {
      href: "/casino/aura-dream-catcher",
      code: "",
      casino: "Aura Gaming",
      provider: "Aura",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/aura/Dreamcatcher.png",
      alt: "dream catcher",
      name: "Dream Catcher",
    },
    HiLowAura: {
      href: "/casino/aura-hi-low",
      code: "",
      casino: "Aura Gaming",
      provider: "Aura",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/aura/Highlow.png",
      alt: "hi low",
      name: "Hi Low",
    },
    MatkaAura: {
      href: "/casino/aura-matka",
      code: "",
      casino: "Aura Gaming",
      provider: "Aura",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/aura/Matka.png",
      alt: "matka",
      name: "Matka",
    },
    TeenpattiOpenAura: {
      href: "/casino/aura-teenpatti-open",
      code: "",
      casino: "Aura Gaming",
      provider: "Aura",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/aura/OpenTeenpatti.png",
      alt: "teenpatti open",
      name: "Teenpatti Open",
    },
    Race2020Aura: {
      href: "/casino/aura-race-20-20",
      code: "",
      casino: "Aura Gaming",
      provider: "Aura",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/aura/Race2020.png",
      alt: "race 20 20",
      name: "Race 20 20",
    },
    TrioAura: {
      href: "/casino/aura-trio",
      code: "",
      casino: "Aura Gaming",
      provider: "Aura",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/aura/Trio.png",
      alt: "trio",
      name: "Trio",
    },
  },
  sports: {
    InplayCricket: {
      href: "/exchange/Inplay",
      code: "",
      casino: "",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/sports/In-play.png",
      alt: "inplay",
      name: "Inplay",
    },
    Cricket: {
      href: "/exchange/Cricket",
      code: "",
      casino: "",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/sports/cricket.png",
      alt: "cricket",
      name: "Cricket",
    },
    Soccer: {
      href: "/exchange/Soccer",
      code: "",
      casino: "",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/sports/Soccer.png",
      alt: "soccer",
      name: "Soccer",
    },
    Tennis: {
      href: "/exchange/Tennis",
      code: "",
      casino: "",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/sports/Tennis.png",
      alt: "tennis",
      name: "Tennis",
    },
    InplayCricket2: {
      href: "/exchange/Inplay",
      code: "",
      casino: "",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/sports/In-play.png",
      alt: "inplay",
      name: "Inplay",
    },
    Cricket2: {
      href: "/exchange/Cricket",
      code: "",
      casino: "",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/sports/cricket.png",
      alt: "cricket",
      name: "Cricket",
    },
    Soccer2: {
      href: "/exchange/Soccer",
      code: "",
      casino: "",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/sports/Soccer.png",
      alt: "soccer",
      name: "Soccer",
    },
    Tennis2: {
      href: "/exchange/Tennis",
      code: "",
      casino: "",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/sports/Tennis.png",
      alt: "tennis",
      name: "Tennis",
    },
  },
  providers: {
    ezugi: {
      href: "/casino/ezugi",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/providers/all-prvdr/Ezugi.webp",
      name: "Ezugi",
    },
    evolution: {
      href: "/casino/evolution",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/providers/all-prvdr/Evolution.webp",
      name: "Evolution",
    },
    vivo: {
      href: "/casino/vivo",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/providers/all-prvdr/Vivo.webp",
      name: "Vivo",
    },
    qtech: {
      href: "/casino/qtech",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/providers/all-prvdr/Qtech.webp",
      name: "Qtech",
    },
    supernowa: {
      href: "/casino/supernowa",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/providers/all-prvdr/Supernowa.webp",
      name: "Supernowa",
    },
    xpg: {
      href: "/casino/xpg",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/providers/all-prvdr/XPG.webp",
      name: "XPG",
    },
    worldcasino: {
      href: "/casino/worldcasino",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/providers/all-prvdr/WorldCasino.webp",
      name: "World Casino",
    },
    slots: {
      href: "/slots",
      code: "",
      casino: "slots",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/providers/all-prvdr/Slots.webp",
      name: "Slots",
    },
    auraGames: {
      href: "/aura-games",
      code: "",
      casino: "aura",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/providers/all-prvdr/AuraGames.webp",
      name: "Aura E-gaming",
    },
    kingmaker: {
      href: "/kingmaker",
      code: "",
      casino: "kingmaker",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/providers/all-prvdr/Kingmaker.webp",
      name: "kingmaker",
    },
    // aesexy: {
    //   href: "/casino/aesexy",
    //   code: "",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: pageUrlGenerator("/home"),
    //   imgUrl: "./images/providers/all-prvdr/AeSexy.webp",
    //   name: "AE Sexy",
    // },
    // powergames: {
    //   href: "/casino/powergames",
    //   code: "",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: pageUrlGenerator("/home"),
    //   imgUrl: "./images/providers/all-prvdr/Powergames.webp",
    //   name: "Power Games",
    // },
  },
};
