import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom/dist";

import Home from "../assets/images/icons/foot-menu/home.webp";
import HomeGrey from "../assets/images/icons/foot-menu/home-grey.webp";
import Menu from "../assets/images/icons/foot-menu/menu.webp";
import MenuGrey from "../assets/images/icons/foot-menu/menu-grey.webp";
import Casino from "../assets/images/icons/foot-menu/casino.webp";
import CasinoGrey from "../assets/images/icons/foot-menu/casino-grey.webp";
import Sports from "../assets/images/icons/foot-menu/sports.webp";
import SportsGrey from "../assets/images/icons/foot-menu/sports-grey.webp";
import Ezugi from "../assets/images/games-icon/headicon/ezugi.png";
import Evolution from "../assets/images/games-icon/headicon/evolution.png";

const footerPages = [
  "home",
  "menu",
  "casino",
  "exchange",
  "casino/evolution",
  "casino/ezugi",
];

const BottomNav = () => {
  const location = useLocation();
  const [activePage, setActivePage] = useState("");
  const [showLeftbarToggle, setShowLeftbarToggle] = useState(false);
  const htmlElement = document.documentElement;

  useEffect(() => {
    const matchedValue = footerPages.find((f) =>
      location?.pathname.includes(f)
    );
    if (matchedValue) {
      setActivePage(matchedValue);
    }
  }, [location]);

  const handleToggleClick = () => {
    const realTimeValue = !showLeftbarToggle;
    if (realTimeValue) {
      htmlElement.style.overflow = "hidden";
      document.body.classList.add("leftbar-opened");
    } else {
      htmlElement.style.overflow = "auto";
      document.body.classList.remove("leftbar-opened");
    }
    setShowLeftbarToggle(realTimeValue);
  };

  return (
    <div className="footer-menu">
      <ul>
        <li>
          <a className={activePage === "home" ? "active" : ""} href="/home">
            <img src={Home} alt="home" className="prime" />
            <img src={HomeGrey} alt="home" className="no-prime" />
            <span>Home</span>
          </a>
        </li>
        <li>
          <a
            className={activePage === "menu" ? "active" : ""}
            href="javascript:void(0)"
            onClick={() => handleToggleClick()}
          >
            <img src={Menu} alt="menu" className="prime" />
            <img src={MenuGrey} alt="menu" className="no-prime" />
            <span>Menu</span>
          </a>
        </li>
        <li>
          <a className={activePage === "casino" ? "active" : ""} href="/casino">
            <img src={Casino} alt="casino" className="prime" />
            <img src={CasinoGrey} alt="casino" className="no-prime" />
            <span>Casino</span>
          </a>
        </li>
        <li>
          <a
            className={activePage === "exchange" ? "active" : ""}
            href="/exchange/Cricket"
          >
            <img src={Sports} alt="sports" className="prime" />
            <img src={SportsGrey} alt="sports" className="no-prime" />
            <span>Sports</span>
          </a>
        </li>
        {/* <li>
            <a href={CasinoProviders["evolution"]?.href}>
              <img src={Evolution} alt="evolution" />
              <span>Evolution</span>
            </a>
          </li> */}
        {/* <li>
            <a href={CasinoProviders["ezugi"]?.href}>
              <img src={Ezugi} alt="ezugi" />
              <span>Ezugi</span>
            </a>
          </li> */}
      </ul>
    </div>
  );
};

export default BottomNav;
